<template>
    <div id="about_page">

        <!-- Start About -->
        <About :partnerList="partnerList" />
        <!-- End About -->

        <!-- Start Service_About -->
        <Service_About :partnerList="partnerList"/>
        <!-- End Service_About -->

        <section data-v-731136a8="" data-v-3d996ecc="" id="vision">
            <div class="container" data-v-731136a8="">
                <div id="vision_wrapper" class="d-none d-sm-none d-md-none d-lg-block d-xl-block" data-v-731136a8="">
                    <div class="row grid gap-3" data-v-731136a8="">
                        <div class="col content_wrapper p-3" data-v-731136a8="">
                            <h3 class="fw-bold mb-4 wow animate__ animate__backInDown animate__slow           animate__animated "
                                data-wow-offset="1" data-wow-delay="0.1s" data-v-731136a8=""><img
                                    src="@/assets/img/our_vision.1423f036.svg" alt="" class="ms-3"
                                    data-v-731136a8="">رؤيتنا
                            </h3>
                            <p class="text-justify wow animate__ animate__backInUp animate__slow            animate__animated"
                                data-wow-offset="1" data-wow-delay="0.2s" data-v-731136a8="">
                             {{ partnerList.vision }}
                            </p>
                        </div>
                        <div class="col content_wrapper p-3" data-v-731136a8="">
                            <h3 class="fw-bold mb-4 wow animate__ animate__backInDown animate__slow            animate__animated"
                                data-wow-offset="1" data-wow-delay="0.1s" data-v-731136a8=""><img
                                    src="@/assets/img/our_message.589fa009.svg" alt="" class="ms-3"
                                    data-v-731136a8="">رسالتنا
                            </h3>
                            <p class="text-justify wow animate__ animate__backInUp animate__slow            animate__animated"
                                data-wow-offset="1" data-wow-delay="0.2s" data-v-731136a8="">
                                {{ partnerList.message }}
                            </p>
                        </div>
                    </div>
                </div>
                <div id="vision_wrapper_mobile" class="d-block d-sm-block d-md-block d-lg-none d-xl-none"
                    data-v-731136a8="">
                    <div class="content_wrapper_mobile p-3 mb-3" data-v-731136a8="">
                        <h3 class="fw-bold mb-4 wow animate__animated animate__backInDown animate__slow animate__animated"
                            data-wow-offset="1" data-wow-delay="0.1s" data-v-731136a8=""><img
                                src="@/assets/img/our_vision.1423f036.svg" alt="" class="ms-3" data-v-731136a8="">رؤيتنا
                        </h3>
                        <p class="text-justify wow animate__animated animate__backInUp animate__slow animate__animated"
                            data-wow-offset="1" data-wow-delay="0.2s" data-v-731136a8="">
                            {{ partnerList.vision }} 
                        </p>
                    </div>
                    <div class="content_wrapper_mobile p-3" data-v-731136a8="">
                        <h3 class="fw-bold mb-4 wow animate__animated animate__backInDown animate__slow animate__animated"
                            data-wow-offset="1" data-wow-delay="0.1s" data-v-731136a8=""><img
                                src="@/assets/img/our_message.589fa009.svg" alt="" class="ms-3"
                                data-v-731136a8="">رسالتنا </h3>
                        <p class="text-justify wow animate__animated animate__backInUp animate__slow animate__animated"
                            data-wow-offset="1" data-wow-delay="0.2s" data-v-731136a8="">
                            {{ partnerList.message }}
                        </p>
                    </div>
                </div>
            </div>
        </section>

        <section data-v-4093d3d2="" data-v-3d996ecc="" id="know_us">
            <div class="container" data-v-4093d3d2="">
                <h3 class="text-center fw-bold text-justify mb-4 wow animate__ animate__backInDown animate__slow            animate__animated"
                    data-wow-offset="1" data-wow-delay="0.1s" data-v-4093d3d2=""><img
                        src="@/assets/img/know_us.27af53b2.svg" alt="" class="ms-3" data-v-4093d3d2="">تعرف علينا </h3>
                <div id="video_wrapper"
                    class="ratio ratio-21x9 wow animate__ animate__backInUp animate__slow            animate__animated"
                    data-wow-offset="1" data-wow-delay="0.2s" data-v-4093d3d2=""><video
                        poster="@/assets/img/Steam_Video.5e5faff2.webp" controls="" data-v-4093d3d2="">
                        <source :src="partnerList.get_to_know" type="video/webm" data-v-4093d3d2="">
                        <source :src="partnerList.get_to_know" type="video/mp4" data-v-4093d3d2="">
                        Your browser
                        does not support the video tag.
                    </video></div>
            </div>
        </section>

        <!-- Start Footer -->
        <Footer />
        <!-- End Footer -->

    </div>
</template>

<script>
import axios from 'axios'
import About from '@/components/about/About.vue';
import Service_About from '@/components/home/Service.vue';

export default {
    name: 'AboutView',
    data() {
        return {
            partnerList: {
                "id": 1,
                "title": "شركة رائدة في خدمات STEAM التعليمية!\n",
                "title_en": "شركة رائدة في خدمات STEAM التعليمية!\n",
                "description": "نعمل لتأهيل الطلاب في مهارات المستقبل القائمة على النهضة الصناعية الرابعة عن طريق توفير كل متطلبات الحصول على امتياز STEAM التجاري. نهدف إلى تمكين الجهات التعليمية والمدربين من تقديم بيئة تعليمية مستدامة ومبتكرة تعزز مهارات التطبيق العملي والإبداع والتعاون وحل المشكلات.\n\n",
                "description_en": "نعمل لتأهيل الطلاب في مهارات المستقبل القائمة على النهضة الصناعية الرابعة عن طريق توفير كل متطلبات الحصول على امتياز STEAM التجاري. نهدف إلى تمكين الجهات التعليمية والمدربين من تقديم بيئة تعليمية مستدامة ومبتكرة تعزز مهارات التطبيق العملي والإبداع والتعاون وحل المشكلات.\n\n",
                "vision": "أن نكون قادرين على تقديم كافة الخدمات التي تمكن الجهات التعليمية والمدربين النهوض بالطلاب نحو مستقبل قائم على النهضة الصناعية الرابعة وتعزيز ثقتهم بنفسهم",
                "vision_en": "أن نكون قادرين على تقديم كافة الخدمات التي تمكن الجهات التعليمية والمدربين النهوض بالطلاب نحو مستقبل قائم على النهضة الصناعية الرابعة وتعزيز ثقتهم بنفسهم",
                "message": "تنمية روح الإبداع للطلاب ومساعتهم على خلق حلول مبتكرة لمواجهة المشاكل المجتمعية",
                "message_en": "تنمية روح الإبداع للطلاب ومساعتهم على خلق حلول مبتكرة لمواجهة المشاكل المجتمعية",
                "get_to_know": "https://admin.steamademy.com/uploads/about/STEAMademy.da38eecf.mp4#t=1",
                "icon": "https://admin.steamademy.com/uploads/about/About_kids.08c90939.webp",
                "color": "#fabe62",
                "status": 1,
                "upperSection": 0,
                "created_at": "-000001-11-30T00:00:00.000000Z",
                "updated_at": "2025-02-12T12:09:03.000000Z",
                "user_id": 1,
            },
            url: process.env.VUE_APP_URL, //"http://admin.steamademy.com",//"http://127.0.0.1:8000"
        };
    },

    components: {
        About,
        Service_About,
    },
    mounted() {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        this.getRoboDBlist()
    },

    methods: {
        async getRoboDBlist() {
            await axios.get(`${this.url}/api/robo-about`).then(response => {
                console.log(response.data.data);
                this.partnerList = response.data.data;
            }).catch(error => {
                this.partnerList = []
            })
        },
    },
}

</script>
<style scoped src="@/assets/css/app.a60e7e5f.css"></style>
<style scoped lang="scss">
#about_page {

    // https://www.w3schools.com/cssref/pr_background-image.php
    /* The image used */
    background-image: url("@/assets/images/about/about_BG.jpg");

    /* Used if the image is unavailable */
    background-color: $White;
    /* Center the image */
    background-position: center;
    /* Repeat the image */
    background-repeat: repeat;
    /* Resize the background image to cover the entire container */
    background-size: cover;

}
</style>