<template>
  <!-- import navbar in main.js file -->
  <Navbar />
  <router-view/>
</template>

<script>
export default {

}
</script>

<style lang="scss">
#app {
  // font-family: Avenir, Helvetica, Arial, sans-serif;
  font-family: 'Bahij_TheSansArabic', Arial, sans-serif ;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  direction: rtl;
  // text-align: center;
  // color: #2c3e50;
}

/* nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
} */

#app a{
  text-decoration: none;
}
</style>
