<template>
    <template v-if="partnerList">
        <section data-v-4ffecce8="" id="commercial_concession">
            <div class="container" data-v-4ffecce8="">
                <div class="row" data-v-4ffecce8="">
                    <div id="text_content" class="col-12 col-sm-12 col-md-12 col-lg-8 col-xl-8 my-auto"
                        data-v-4ffecce8="">
                        <h3 class="fw-bold text-justify mb-4 wow animate__ animate__backInDown animate__slow  animate__animated"
                            data-wow-offset="1" data-wow-delay="0.1s" data-v-4ffecce8=""
                            style="visibility: visible; animation-delay: 0.1s;">
                            {{ partnerList.name }}
                        </h3>
                        <p class="text-justify mb-4 wow animate__ animate__backInUp animate__slow  " data-wow-offset="1"
                            data-wow-delay="0.2s" data-v-4ffecce8="">
                            {{ partnerList.description }}
                        </p>
                    </div>
                    <div id="img_content"
                        class="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4 wow animate__ animate__fadeIn animate__slow   animate__animated"
                        data-wow-offset="1" data-wow-delay="0.3s" data-v-4ffecce8="">
                        <div id="img_wrapper" data-v-4ffecce8=""><img :src="partnerList.logo" alt="" data-v-4ffecce8="">
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section data-v-de40eb92="" id="advantages">
            <div data-v-de40eb92="" class="container">
                <h3 class="fw-bold mb-4 text-justify wow animate__ animate__backInDown animate__slow                                                                                                                                                                            "
                    data-wow-offset="1" data-wow-delay="0.1s" data-v-de40eb92="">
                    {{ partnerList.steam_feature_title }}
                </h3>
                <div id="content_wrapper"
                    class="d-none d-sm-none d-md-none d-lg-block d-xl-block wow animate__ animate__backInUp animate__slow   animate__animated                                                                                                                                                                         "
                    data-wow-offset="1" data-wow-delay="0.2s" data-v-de40eb92="">
                    <div class="row gy-4" data-v-de40eb92="">
                        <template v-for="(steam, key) in this.steam_features" :key="key">
                            <div class="col-lg-6 col-xl-4 d-flex align-items-stretch" data-v-de40eb92="">
                                <div class="card" data-v-de40eb92="">
                                    <img :src="steam.logo" class="card-img-top"
                                        alt="intercultural-kids-working-pairs-while-girls-controlling-virtual-presentation"
                                        data-v-de40eb92="">
                                    <div class="card-body" data-v-de40eb92="">
                                        <h5 class="card-title fw-bold" data-v-de40eb92="">
                                            {{ steam.title }}
                                        </h5>
                                        <p class="card-text" data-v-de40eb92="">
                                            {{ steam.description }}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </template>


                        <!-- <div class="col-lg-6 col-xl-4 d-flex align-items-stretch" data-v-de40eb92="">
                            <div class="card" data-v-de40eb92=""><img src="@/assets/img/advantage_2.cb029976.webp"
                                    class="card-img-top" alt="metal-3d-printer-with-abstract-lines" data-v-de40eb92="">
                                <div class="card-body" data-v-de40eb92="">
                                    <h5 class="card-title fw-bold" data-v-de40eb92="">الأدوات والأجهزة</h5>
                                    <p class="card-text" data-v-de40eb92="">نوفر الأدوات والأجهزة اللازمة للتعلم وتطبيق
                                        نتائج التعلم من خلال مشاريع واقعية، تشمل هذه الأدوات مجموعات الروبوتات والدوائر
                                        الكهربائية وإلكترونيات تطبيق إنترنت الأشياء والطابعات ثلاثية الأبعاد.</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-xl-4 d-flex align-items-stretch" data-v-de40eb92="">
                            <div class="card" data-v-de40eb92=""><img src="@/assets/img/advantage_3.bc3d1642.webp"
                                    class="card-img-top" alt="" data-v-de40eb92="">
                                <div class="card-body" data-v-de40eb92="">
                                    <h5 class="card-title fw-bold" data-v-de40eb92="">الأدلة وجودة التشغيل</h5>
                                    <p class="card-text" data-v-de40eb92="">نوفر لك جميع أدلة التشغيل لتقدم نهج STEAM
                                        التعليمي بأفضل كفاءة تشغيل، تشمل أدلة الإجراءات التشغيلية متابعة سير عملية
                                        التعلم ونظام تقييم الطلاب والمدربين والموظفين بالإضافة لأدلة الصيانة الدورية
                                        وتحضير المعامل والبحث والتطوير.</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-xl-4 d-flex align-items-stretch" data-v-de40eb92="">
                            <div class="card" data-v-de40eb92=""><img src="@/assets/img/advantage_4.87acb73a.webp"
                                    class="card-img-top" alt="father-sons-making-robot" data-v-de40eb92="">
                                <div class="card-body" data-v-de40eb92="">
                                    <h5 class="card-title fw-bold" data-v-de40eb92="">البرمجيات التعليمية</h5>
                                    <p class="card-text" data-v-de40eb92="">نقدم كل البرمجيات التي تحتاجها في عملية
                                        التعليم أو إنشاء المشاريع أو حتى متابعة الطلاب والأهل: برامج التصميم الهندسي
                                        مثل: AutoCAD, Tinkercad, SolidWorks اللغات البرمجية. C# , C++, Python تطبيقات
                                        متابعة الأهل. منصات الابتكار ودراسات الجدوى.</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-xl-4 d-flex align-items-stretch" data-v-de40eb92="">
                            <div class="card" data-v-de40eb92=""><img src="@/assets/img/advantage_5.a1aacf58.webp"
                                    class="card-img-top" alt="tech-devices-icons-connected-digital-planet-earth"
                                    data-v-de40eb92="">
                                <div class="card-body" data-v-de40eb92="">
                                    <h5 class="card-title fw-bold" data-v-de40eb92="">اختبارات STEAM الدولية</h5>
                                    <p class="card-text" data-v-de40eb92="">نقدم اختبارات STEAM الدولية لتتمكن من قياس
                                        جودة العملية التعليمية سواءً للطلاب أو المدربين أو الجهات التعليمية.</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-xl-4 d-flex align-items-stretch" data-v-de40eb92="">
                            <div class="card" data-v-de40eb92=""><img src="@/assets/img/advantage_6.832529b0.webp"
                                    class="card-img-top" alt="disabled-man-shows-financial-diagrams-office"
                                    data-v-de40eb92="">
                                <div class="card-body" data-v-de40eb92="">
                                    <h5 class="card-title fw-bold" data-v-de40eb92="">تأهيل المدربين</h5>
                                    <p class="card-text" data-v-de40eb92="">نقدم برامج تدريب متخصصة لمعلمين نهج STEAM
                                        التعليمي سواءً كانوا تابعين للجهات التعليمية أو مدربين منفردين ، تشمل عملية
                                        التأهيل تدريبًا على ما يلى: المفاهيم الأساسية في STEAM استراتيجيات التعليم النشط
                                        والتفاعلي التكنولوجيا في التعليم القيادة وإدارة المدارس STEAM</p>
                                </div>
                            </div>
                        </div> -->
                    </div>
                </div>
                <div data-v-de40eb92="" id="content_wrapper_mobile"
                    class="d-block d-sm-block d-md-block d-lg-none d-xl-none wow animate__animated animate__backInUp animate__slow "
                    data-wow-offset="1" data-wow-delay="0.2s">
                    <div data-v-de40eb92="" id="carouselExampleSlidesOnly" class="carousel slide"
                        data-bs-ride="carousel">
                            <div class="carousel-inner" data-v-de40eb92="">
                                <template v-for="(steam, key) in this.steam_features" :key="key">
                                <div class="carousel-item active" data-bs-interval="5000" data-v-de40eb92=""
                                    v-if="key == 0">
                                    <div class="card" data-v-de40eb92=""><img
                                            src="@/assets/img/advantage_1.c16c529c.webp" class="card-img-top"
                                            alt="intercultural-kids-working-pairs-while-girls-controlling-virtual-presentation"
                                            data-v-de40eb92="">
                                        <div class="card-body" data-v-de40eb92="">
                                            <h5 class="card-title fw-bold" data-v-de40eb92="">التجهيزات والأثاث</h5>
                                            <p class="card-text" data-v-de40eb92="">نضمن لك أن جهتك التعليمية
                                                ستكون&nbsp;
                                                مناسبة لتنفيذ نهج STEAM التعليمي ومشجعة على التعلم عن طريق توفير
                                                STEAMademy
                                                لتصميم داخلي فريد للجهات التعليمية. نجهز المعامل بكل المعدات ونوفر كل
                                                التجهيزات المناسبة مثل وحدات التخزين وطاولات المنافسة وأجهزة الحاسب
                                                الآلي
                                                والشاشات التفاعلية.</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="carousel-item" data-bs-interval="5000" data-v-de40eb92="" v-else>
                                    <div class="card" data-v-de40eb92=""><img
                                            src="@/assets/img/advantage_1.c16c529c.webp" class="card-img-top"
                                            alt="intercultural-kids-working-pairs-while-girls-controlling-virtual-presentation"
                                            data-v-de40eb92="">
                                        <div class="card-body" data-v-de40eb92="">
                                            <h5 class="card-title fw-bold" data-v-de40eb92="">التجهيزات والأثاث</h5>
                                            <p class="card-text" data-v-de40eb92="">نضمن لك أن جهتك التعليمية
                                                ستكون&nbsp;
                                                مناسبة لتنفيذ نهج STEAM التعليمي ومشجعة على التعلم عن طريق توفير
                                                STEAMademy
                                                لتصميم داخلي فريد للجهات التعليمية. نجهز المعامل بكل المعدات ونوفر
                                                كل
                                                التجهيزات المناسبة مثل وحدات التخزين وطاولات المنافسة وأجهزة الحاسب
                                                الآلي
                                                والشاشات التفاعلية.</p>
                                        </div>
                                    </div>
                                </div>
                            </template>
                        </div>
                        <!-- <div class="carousel-inner" data-v-de40eb92="">
                            <div class="carousel-item active" data-bs-interval="5000" data-v-de40eb92="">
                                <div class="card" data-v-de40eb92=""><img src="@/assets/img/advantage_1.c16c529c.webp"
                                        class="card-img-top"
                                        alt="intercultural-kids-working-pairs-while-girls-controlling-virtual-presentation"
                                        data-v-de40eb92="">
                                    <div class="card-body" data-v-de40eb92="">
                                        <h5 class="card-title fw-bold" data-v-de40eb92="">التجهيزات والأثاث</h5>
                                        <p class="card-text" data-v-de40eb92="">نضمن لك أن جهتك التعليمية ستكون&nbsp;
                                            مناسبة لتنفيذ نهج STEAM التعليمي ومشجعة على التعلم عن طريق توفير STEAMademy
                                            لتصميم داخلي فريد للجهات التعليمية. نجهز المعامل بكل المعدات ونوفر كل
                                            التجهيزات المناسبة مثل وحدات التخزين وطاولات المنافسة وأجهزة الحاسب الآلي
                                            والشاشات التفاعلية.</p>
                                    </div>
                                </div>
                            </div> -->
                            <!-- <div class="carousel-item" data-bs-interval="5000" data-v-de40eb92="">
                                <div class="card" data-v-de40eb92=""><img src="@/assets/img/advantage_2.cb029976.webp"
                                        class="card-img-top" alt="metal-3d-printer-with-abstract-lines"
                                        data-v-de40eb92="">
                                    <div class="card-body" data-v-de40eb92="">
                                        <h5 class="card-title fw-bold" data-v-de40eb92="">الأدوات والأجهزة</h5>
                                        <p class="card-text" data-v-de40eb92="">نوفر الأدوات والأجهزة اللازمة للتعلم
                                            وتطبيق نتائج التعلم من خلال مشاريع واقعية، تشمل هذه الأدوات مجموعات
                                            الروبوتات والدوائر الكهربائية وإلكترونيات تطبيق إنترنت الأشياء والطابعات
                                            ثلاثية الأبعاد.</p>
                                    </div>
                                </div>
                            </div>
                            <div class="carousel-item" data-bs-interval="5000" data-v-de40eb92="">
                                <div class="card" data-v-de40eb92=""><img src="@/assets/img/advantage_3.bc3d1642.webp"
                                        class="card-img-top" alt="" data-v-de40eb92="">
                                    <div class="card-body" data-v-de40eb92="">
                                        <h5 class="card-title fw-bold" data-v-de40eb92="">الأدلة وجودة التشغيل</h5>
                                        <p class="card-text" data-v-de40eb92="">نوفر لك جميع أدلة التشغيل لتقدم نهج
                                            STEAM التعليمي بأفضل كفاءة تشغيل، تشمل أدلة الإجراءات التشغيلية متابعة سير
                                            عملية التعلم ونظام تقييم الطلاب والمدربين والموظفين بالإضافة لأدلة الصيانة
                                            الدورية وتحضير المعامل والبحث والتطوير.</p>
                                    </div>
                                </div>
                            </div>
                            <div class="carousel-item" data-bs-interval="5000" data-v-de40eb92="">
                                <div class="card" data-v-de40eb92=""><img src="@/assets/img/advantage_4.87acb73a.webp"
                                        class="card-img-top" alt="father-sons-making-robot" data-v-de40eb92="">
                                    <div class="card-body" data-v-de40eb92="">
                                        <h5 class="card-title fw-bold" data-v-de40eb92="">البرمجيات التعليمية</h5>
                                        <p class="card-text" data-v-de40eb92="">نقدم كل البرمجيات التي تحتاجها في عملية
                                            التعليم أو إنشاء المشاريع أو حتى متابعة الطلاب والأهل: برامج التصميم الهندسي
                                            مثل: AutoCAD, Tinkercad, SolidWorks اللغات البرمجية. C# , C++, Python
                                            تطبيقات متابعة الأهل. منصات الابتكار ودراسات الجدوى.</p>
                                    </div>
                                </div>
                            </div>
                            <div class="carousel-item" data-bs-interval="5000" data-v-de40eb92="">
                                <div class="card" data-v-de40eb92=""><img src="@/assets/img/advantage_5.a1aacf58.webp"
                                        class="card-img-top" alt="tech-devices-icons-connected-digital-planet-earth"
                                        data-v-de40eb92="">
                                    <div class="card-body" data-v-de40eb92="">
                                        <h5 class="card-title fw-bold" data-v-de40eb92="">اختبارات STEAM الدولية</h5>
                                        <p class="card-text" data-v-de40eb92="">نقدم اختبارات STEAM الدولية لتتمكن من
                                            قياس جودة العملية التعليمية سواءً للطلاب أو المدربين أو الجهات التعليمية.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="carousel-item" data-bs-interval="5000" data-v-de40eb92="">
                                <div class="card" data-v-de40eb92=""><img src="@/assets/img/advantage_6.832529b0.webp"
                                        class="card-img-top" alt="disabled-man-shows-financial-diagrams-office"
                                        data-v-de40eb92="">
                                    <div class="card-body" data-v-de40eb92="">
                                        <h5 class="card-title fw-bold" data-v-de40eb92="">تأهيل المدربين</h5>
                                        <p class="card-text" data-v-de40eb92="">نقدم برامج تدريب متخصصة لمعلمين نهج
                                            STEAM التعليمي سواءً كانوا تابعين للجهات التعليمية أو مدربين منفردين ، تشمل
                                            عملية التأهيل تدريبًا على ما يلى: المفاهيم الأساسية في STEAM استراتيجيات
                                            التعليم النشط والتفاعلي التكنولوجيا في التعليم القيادة وإدارة المدارس STEAM
                                        </p>
                                    </div>
                                </div>
                            </div> -->
                        <!-- </div> -->
                        <div data-v-de40eb92="" class="d-flex justify-content-center"><button data-v-de40eb92=""
                                class="carousel-control-next" type="button" data-bs-target="#carouselExampleSlidesOnly"
                                data-bs-slide="next"><svg data-v-de40eb92="" class="svg-inline--fa fa-chevron-right"
                                    aria-hidden="true" focusable="false" data-prefix="fas" data-icon="chevron-right"
                                    role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
                                    <path class="" fill="currentColor"
                                        d="M310.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-192 192c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L242.7 256 73.4 86.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l192 192z">
                                    </path>
                                </svg></button><button data-v-de40eb92="" class="carousel-control-prev" type="button"
                                data-bs-target="#carouselExampleSlidesOnly" data-bs-slide="prev"><svg data-v-de40eb92=""
                                    class="svg-inline--fa fa-chevron-left" aria-hidden="true" focusable="false"
                                    data-prefix="fas" data-icon="chevron-left" role="img"
                                    xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
                                    <path class="" fill="currentColor"
                                        d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l192 192c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L77.3 256 246.6 86.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-192 192z">
                                    </path>
                                </svg></button></div>
                    </div>
                </div>
            </div>
        </section>
        <section data-v-a33ad11c="" id="content">
            <div class="container" data-v-a33ad11c="">
                <div id="content_wrapper" class="p-3" data-v-a33ad11c="">
                    <div class="row" data-v-a33ad11c="">

                        <div class="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4 wow animate__ animate__backInRight animate__slow       animate__animated                                                                                                                                                                      "
                            data-wow-offset="1" data-wow-delay="0.1s" data-v-a33ad11c="">
                            <div v-html="partnerList.digital_content" />
                            <!-- <h3 class="fw-bold mb-3" data-v-a33ad11c="">المحتوى الرقمي</h3>
                            <p class="mb-3 mb-sm-3 mb-md-3 mb-lg-0" data-v-a33ad11c="">نقدم محتوى تعليمي رقمي تفاعلي
                                متكامل لإعداد المتدربين لوظائف النهضة الصناعية الرابعة، هذا المحتوى قائم على مبادئ نهج
                                STEAM التعليمي وهي: <br data-v-a33ad11c=""> العلوم <br data-v-a33ad11c=""> التكنولوجيا
                                <br data-v-a33ad11c=""> الهندسة <br data-v-a33ad11c=""> الفنون <br data-v-a33ad11c="">
                                الرياضيات
                            </p> -->
                        </div>
                        <div class="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4 wow animate__ animate__backInRight animate__slow                animate__animated                                                                                                                                                            "
                            data-wow-offset="1" data-wow-delay="0.2s" data-v-a33ad11c="">
                            <div v-html="partnerList.aims" />
                            <!-- <h5 class="fw-bold mb-3" data-v-a33ad11c="">يهدف المحتوى لتوفير:</h5>
                            <ul data-v-a33ad11c="">
                                <li data-v-a33ad11c="">برامج تعليمية متكاملة.</li>
                                <li data-v-a33ad11c="">مشاريع تطبيق المفاهيم العلمية. تعليم برمجة الروبوتات.</li>
                                <li data-v-a33ad11c="">تعليم تصميم المواقع والتطبيقات.</li>
                                <li data-v-a33ad11c="">تدريب الطلاب على حل المشكلات العملية.</li>
                                <li data-v-a33ad11c="">محتوى تعليمي متعدد الوسائط. تقييم وتوجيه شامل.</li>
                            </ul> -->
                        </div>
                        <div class="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4 d-flex justify-content-center align-items-center wow animate__ animate__backInLeft animate__slow     animate__animated                                                                                                                                                                      "
                            data-wow-offset="1" data-wow-delay="0.3s" data-v-a33ad11c="">
                            <div id="img_wrapper" data-v-a33ad11c=""><img :src="partnerList.image_one"
                                    alt="children-standing-sideways-camera-looking-charge-boards"
                                    class="d-none d-sm-none d-md-block d-lg-none d-xl-block" data-v-a33ad11c=""><img
                                    :src="partnerList.image_two"
                                    alt="children-standing-sideways-camera-looking-charge-boards"
                                    class="d-block d-sm-block d-md-none d-lg-block d-xl-none" data-v-a33ad11c=""></div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </template>

    <!-- Start Footer -->
    <Footer />
    <!-- End Footer -->

</template>

<script>
import axios from 'axios'

import Header from '@/components/home/Header.vue';
export default {
    name: 'CommercialConcessionView',
    components: {
        Header,
    },
    data() {
        return {
            partnerList: {
                "id": 1,
                "name": "امتياز STEAM التجاري\r\n",
                "name_en": "امتياز STEAM التجاري\r\n",
                "description": "يوفر لك منظومة متكاملة لتحقيق أفضل عائد على استثماراتك بداية من التجهيزات والمحتوى العلمي وتأهيل فريقك وحتى وسائل التواصل مع الطلاب والتسويق كل ما عليك هو أن تؤمن بقدرتك على تغيير المستقبل وتحقيق أهدا",
                "description_en": "يوفر لك منظومة متكاملة لتحقيق أفضل عائد على استثماراتك بداية من التجهيزات والمحتوى العلمي وتأهيل فريقك وحتى وسائل التواصل مع الطلاب والتسويق كل ما عليك هو أن تؤمن بقدرتك على تغيير المستقبل وتحقيق أهدا",
                "logo": "Kid_pic.81e6150f.webp",
                "steam_feature_title": "إليك مميزات الامتياز التجاري STEAMdemy\r\n",
                "steam_feature_title_en": "إليك مميزات الامتياز التجاري STEAMdemy\r\n",
                "digital_content": "\u003Cdiv class=\"col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4 wow animate__ animate__backInRight animate__slow       animate__animated                                                                                                                                                                      \"\r\n                        data-wow-offset=\"1\" data-wow-delay=\"0.1s\" data-v-a33ad11c=\"\"\u003E\r\n                        \u003Ch3 class=\"fw-bold mb-3\" data-v-a33ad11c=\"\"\u003Eالمحتوى الرقمي\u003C/h3\u003E\r\n                        \u003Cp class=\"mb-3 mb-sm-3 mb-md-3 mb-lg-0\" data-v-a33ad11c=\"\"\u003Eنقدم محتوى تعليمي رقمي تفاعلي\r\n                            متكامل لإعداد المتدربين لوظائف النهضة الصناعية الرابعة، هذا المحتوى قائم على مبادئ نهج\r\n                            STEAM التعليمي وهي: \u003Cbr data-v-a33ad11c=\"\"\u003E العلوم \u003Cbr data-v-a33ad11c=\"\"\u003E التكنولوجيا\r\n                            \u003Cbr data-v-a33ad11c=\"\"\u003E الهندسة \u003Cbr data-v-a33ad11c=\"\"\u003E الفنون \u003Cbr data-v-a33ad11c=\"\"\u003E\r\n                            الرياضيات\r\n                        \u003C/p\u003E\r\n                    \u003C/div\u003E",
                "digital_content_en": "\u003Cdiv class=\"col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4 wow animate__ animate__backInRight animate__slow       animate__animated                                                                                                                                                                      \"\r\n                        data-wow-offset=\"1\" data-wow-delay=\"0.1s\" data-v-a33ad11c=\"\"\u003E\r\n                        \u003Ch3 class=\"fw-bold mb-3\" data-v-a33ad11c=\"\"\u003Eالمحتوى الرقمي\u003C/h3\u003E\r\n                        \u003Cp class=\"mb-3 mb-sm-3 mb-md-3 mb-lg-0\" data-v-a33ad11c=\"\"\u003Eنقدم محتوى تعليمي رقمي تفاعلي\r\n                            متكامل لإعداد المتدربين لوظائف النهضة الصناعية الرابعة، هذا المحتوى قائم على مبادئ نهج\r\n                            STEAM التعليمي وهي: \u003Cbr data-v-a33ad11c=\"\"\u003E العلوم \u003Cbr data-v-a33ad11c=\"\"\u003E التكنولوجيا\r\n                            \u003Cbr data-v-a33ad11c=\"\"\u003E الهندسة \u003Cbr data-v-a33ad11c=\"\"\u003E الفنون \u003Cbr data-v-a33ad11c=\"\"\u003E\r\n                            الرياضيات\r\n                        \u003C/p\u003E\r\n                    \u003C/div\u003E",
                "aims": "  \u003Cdiv class=\"col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4 wow animate__ animate__backInRight animate__slow                animate__animated                                                                                                                                                            \"\r\n                        data-wow-offset=\"1\" data-wow-delay=\"0.2s\" data-v-a33ad11c=\"\"\u003E\r\n                        \u003Ch5 class=\"fw-bold mb-3\" data-v-a33ad11c=\"\"\u003Eيهدف المحتوى لتوفير:\u003C/h5\u003E\r\n                        \u003Cul data-v-a33ad11c=\"\"\u003E\r\n                            \u003Cli data-v-a33ad11c=\"\"\u003Eبرامج تعليمية متكاملة.\u003C/li\u003E\r\n                            \u003Cli data-v-a33ad11c=\"\"\u003Eمشاريع تطبيق المفاهيم العلمية. تعليم برمجة الروبوتات.\u003C/li\u003E\r\n                            \u003Cli data-v-a33ad11c=\"\"\u003Eتعليم تصميم المواقع والتطبيقات.\u003C/li\u003E\r\n                            \u003Cli data-v-a33ad11c=\"\"\u003Eتدريب الطلاب على حل المشكلات العملية.\u003C/li\u003E\r\n                            \u003Cli data-v-a33ad11c=\"\"\u003Eمحتوى تعليمي متعدد الوسائط. تقييم وتوجيه شامل.\u003C/li\u003E\r\n                        \u003C/ul\u003E\r\n                    \u003C/div\u003E",
                "aims_en": "  \u003Cdiv class=\"col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4 wow animate__ animate__backInRight animate__slow                animate__animated                                                                                                                                                            \"\r\n                        data-wow-offset=\"1\" data-wow-delay=\"0.2s\" data-v-a33ad11c=\"\"\u003E\r\n                        \u003Ch5 class=\"fw-bold mb-3\" data-v-a33ad11c=\"\"\u003Eيهدف المحتوى لتوفير:\u003C/h5\u003E\r\n                        \u003Cul data-v-a33ad11c=\"\"\u003E\r\n                            \u003Cli data-v-a33ad11c=\"\"\u003Eبرامج تعليمية متكاملة.\u003C/li\u003E\r\n                            \u003Cli data-v-a33ad11c=\"\"\u003Eمشاريع تطبيق المفاهيم العلمية. تعليم برمجة الروبوتات.\u003C/li\u003E\r\n                            \u003Cli data-v-a33ad11c=\"\"\u003Eتعليم تصميم المواقع والتطبيقات.\u003C/li\u003E\r\n                            \u003Cli data-v-a33ad11c=\"\"\u003Eتدريب الطلاب على حل المشكلات العملية.\u003C/li\u003E\r\n                            \u003Cli data-v-a33ad11c=\"\"\u003Eمحتوى تعليمي متعدد الوسائط. تقييم وتوجيه شامل.\u003C/li\u003E\r\n                        \u003C/ul\u003E\r\n                    \u003C/div\u003E",
                "created_at": "2025-02-24 10:37:19",
                "updated_at": "2025-02-24 10:37:19"
            },
            steam_features: [
                {
                    "id": 1,
                    "steam_frenchise_id": 1,
                    "title": "التجهيزات والأثاث\r\n",
                    "title_en": "التجهيزات والأثاث\r\n",
                    "description": "نضمن لك أن جهتك التعليمية ستكون  مناسبة لتنفيذ نهج STEAM التعليمي ومشجعة على التعلم عن طريق توفير STEAMademy لتصميم داخلي فريد للجهات التعليمية. نجهز المعامل بكل المعدات ونوفر كل التجهيزات المناسبة مث",
                    "description_en": "نضمن لك أن جهتك التعليمية ستكون  مناسبة لتنفيذ نهج STEAM التعليمي ومشجعة على التعلم عن طريق توفير STEAMademy لتصميم داخلي فريد للجهات التعليمية. نجهز المعامل بكل المعدات ونوفر كل التجهيزات المناسبة مث",
                    "logo": "advantage_1.c16c529c.webp",
                    "created_at": "2025-02-24 10:45:45",
                    "updated_at": "2025-02-24 10:45:45"
                },
                {
                    "id": 2,
                    "steam_frenchise_id": 1,
                    "title": "الأدوات والأجهزة\r\n",
                    "title_en": "الأدوات والأجهزة\r\n",
                    "description": "نوفر الأدوات والأجهزة اللازمة للتعلم وتطبيق نتائج التعلم من خلال مشاريع واقعية، تشمل هذه الأدوات مجموعات الروبوتات والدوائر الكهربائية وإلكترونيات تطبيق إنترنت الأشياء والطابعات ثلاثية الأبعاد.\r\n\r\n",
                    "description_en": "نوفر الأدوات والأجهزة اللازمة للتعلم وتطبيق نتائج التعلم من خلال مشاريع واقعية، تشمل هذه الأدوات مجموعات الروبوتات والدوائر الكهربائية وإلكترونيات تطبيق إنترنت الأشياء والطابعات ثلاثية الأبعاد.\r\n\r\n",
                    "logo": "advantage_2.cb029976.webp",
                    "created_at": "2025-02-24 10:46:22",
                    "updated_at": "2025-02-24 10:46:22"
                },
                {
                    "id": 3,
                    "steam_frenchise_id": 1,
                    "title": "الأدلة وجودة التشغيل\r\n",
                    "title_en": "الأدلة وجودة التشغيل\r\n",
                    "description": "نوفر لك جميع أدلة التشغيل لتقدم نهج STEAM التعليمي بأفضل كفاءة تشغيل، تشمل أدلة الإجراءات التشغيلية متابعة سير عملية التعلم ونظام تقييم الطلاب والمدربين والموظفين بالإضافة لأدلة الصيانة الدورية وتحضير",
                    "description_en": "نوفر لك جميع أدلة التشغيل لتقدم نهج STEAM التعليمي بأفضل كفاءة تشغيل، تشمل أدلة الإجراءات التشغيلية متابعة سير عملية التعلم ونظام تقييم الطلاب والمدربين والموظفين بالإضافة لأدلة الصيانة الدورية وتحضير",
                    "logo": "advantage_3.bc3d1642.webp",
                    "created_at": "2025-02-24 10:46:48",
                    "updated_at": "2025-02-24 10:46:48"
                },
                {
                    "id": 4,
                    "steam_frenchise_id": 1,
                    "title": "البرمجيات التعليمية\r\n",
                    "title_en": "البرمجيات التعليمية\r\n",
                    "description": "نقدم كل البرمجيات التي تحتاجها في عملية التعليم أو إنشاء المشاريع أو حتى متابعة الطلاب والأهل: برامج التصميم الهندسي مثل: AutoCAD, Tinkercad, SolidWorks اللغات البرمجية. C# , C++, Python تطبيقات متابع",
                    "description_en": "نقدم كل البرمجيات التي تحتاجها في عملية التعليم أو إنشاء المشاريع أو حتى متابعة الطلاب والأهل: برامج التصميم الهندسي مثل: AutoCAD, Tinkercad, SolidWorks اللغات البرمجية. C# , C++, Python تطبيقات متابع",
                    "logo": "advantage_4.87acb73a.webp",
                    "created_at": "2025-02-24 10:47:18",
                    "updated_at": "2025-02-24 10:47:18"
                },
                {
                    "id": 5,
                    "steam_frenchise_id": 1,
                    "title": "اختبارات STEAM الدولية\r\n",
                    "title_en": "اختبارات STEAM الدولية\r\n",
                    "description": "نقدم اختبارات STEAM الدولية لتتمكن من قياس جودة العملية التعليمية سواءً للطلاب أو المدربين أو الجهات التعليمية.\r\n\r\n",
                    "description_en": "نقدم اختبارات STEAM الدولية لتتمكن من قياس جودة العملية التعليمية سواءً للطلاب أو المدربين أو الجهات التعليمية.\r\n\r\n",
                    "logo": "advantage_5.a1aacf58.webp",
                    "created_at": "2025-02-24 10:47:43",
                    "updated_at": "2025-02-24 10:47:43"
                },
                {
                    "id": 6,
                    "steam_frenchise_id": 1,
                    "title": "تأهيل المدربين\r\n",
                    "title_en": "تأهيل المدربين\r\n",
                    "description": "نقدم برامج تدريب متخصصة لمعلمين نهج STEAM التعليمي سواءً كانوا تابعين للجهات التعليمية أو مدربين منفردين ، تشمل عملية التأهيل تدريبًا على ما يلى: المفاهيم الأساسية في STEAM استراتيجيات التعليم النشط و",
                    "description_en": "نقدم برامج تدريب متخصصة لمعلمين نهج STEAM التعليمي سواءً كانوا تابعين للجهات التعليمية أو مدربين منفردين ، تشمل عملية التأهيل تدريبًا على ما يلى: المفاهيم الأساسية في STEAM استراتيجيات التعليم النشط و",
                    "logo": "advantage_6.832529b0.webp",
                    "created_at": "2025-02-24 10:48:09",
                    "updated_at": "2025-02-24 10:48:09"
                }
            ],
            url: process.env.VUE_APP_URL, //"http://admin.steamademy.com",//"http://127.0.0.1:8000"
        };
    },
    mounted() {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        this.getRoboDBlist()
    },
    methods: {
        async getRoboDBlist() {
            await axios.get(`${this.url}/api/steam-frenchise`).then(response => {
                console.log(response.data.data);
                this.partnerList = response.data.data;
                this.steam_features = response.data.steam_features;
            }).catch(error => {
                this.partnerList = []
            })
        },
    }
}
</script>

<style scoped src="@/assets/css/app.a60e7e5f.css"></style>